<template>
	<div style="padding: 10px">
		<md-empty-state v-if="data.length == 0" md-icon="event_busy" :md-label="$store.state.lang.sin_registros" />
		<md-card class="md-card-timeline" :md-with-hover="true" v-for="item in data" :key="item.id" v-else>
			<div class="md-subhead" style="padding-bottom: 5px">
				<md-icon>schedule</md-icon>
				<span> {{item.fecha_cancelacion}}</span>
			</div>
			<div class="md-subhead" style="">
				<md-avatar class="md-avatar-icon md-small" style="margin-right: 5px;">{{avatar(nombre(item))}}</md-avatar> {{nombre(item).toUpperCase()}}
			</div>
			<div class="md-subhead" style="padding-bottom: 5px">
				<md-icon class = "md-small">king_bed</md-icon> <span><strong>{{item.categoria}} </strong> | {{item.cuarto}} </span>
			</div>
			<md-card-content>

				<div class="details">
					<div class="md-layout md-gutter ">
						<div class="md-layout-item">
							<md-icon class = "md-small ">flight_land</md-icon>
							<div class = "md-list-item-text">
								<span v-html="wrap(item.entrada)"></span>
								<span class="label">{{lang.entrada}}</span>
							</div>
						</div>
						<div class="md-layout-item">
							<md-icon class = "md-small">flight_takeoff</md-icon>
							<div class = "md-list-item-text">
								<span v-html="wrap(item.salida)"></span>
								<span class="label">{{lang.salida}}</span>
							</div>
						</div>
					</div>

					<div class="md-layout md-gutter">
						<div class="md-layout-item">	<md-icon class = "md-small">contactless</md-icon>
							<div class = "md-list-item-text">
								<span>{{item.agenciaonline}}</span>
								<span class="label">{{lang.canal}}</span>
							</div></div>
						<div class="md-layout-item">
							<md-icon class = "md-small">attach_money</md-icon>
							<div class = "md-list-item-text">
								<span>{{item.total_alojamiento}}</span>
								<span class="label">{{lang.total}}</span>
							</div>
						</div>
					</div>
				</div>
			</md-card-content>
			<div class="md-subfoot">
				<strong> {{item.motivo_cancelacion}}: </strong> {{item.descripcion_cancelacion}}
			</div>
			<div class="md-foot">
				<md-chip>
					<span style="display: flex; align-items: center">
						<md-icon>face</md-icon> <span style="margin-left: 3px">{{item.usuario_creacion}}</span>
					</span>
				</md-chip>
			</div>
		</md-card>

	</div>
</template>
<script>
	import {empty, request, avatar} from '../../../helpers';

	export default {
		computed: {
			lang() {return this.$store.state.lang},
		},
		methods:{
			wrap: function(date){
				if(!empty(date)){
					date = date.split(' ');
					date = date[0];
				}
				return date
			},
			nombre(item){
				let nombre  = (item.nombres)??'';
				let primer_apellido  = (item.apellidos)??'';
				return nombre + ' '+ primer_apellido;
			},

			avatar(name){
				return avatar(name)
			},

			async request(){
				let id = this.$route.params.id;
				this.$store.state.loading = true;
				let r = await request(`${base_url}/resumendiario/canceladashoy?view=json&jwt=${this.$store.state.jwt}`);

				if(r.ok){
					this.data = r.r.data;
				}

				this.$store.state.loading = false;
			}
		},
		mounted() {
			this.request();
		},
		data(){
			return {
				data :[]
			}
		}

	}

</script>
<style lang="scss" scoped>
	.md-card-timeline{
		margin-bottom: 10px;
	}
	.md-subhead{
		padding: 7px 7px;
		border-bottom: 1px solid #b5b5b5;
	}
	.md-foot{
		padding: 7px 7px 10px;
		text-align: right;
	}
	.md-subfoot{
		font-size: 12px;
		letter-spacing: .01em;
		line-height: 20px;
		padding: 7px 15px;
		border-top: 1px solid #b5b5b5;
	}
	.details{
		div.md-layout-item{
			padding-top: 10px;
			display: flex;
			flex-wrap: nowrap;
			align-items: flex-start;
			justify-items: flex-start;
			i {
				margin-right: 4px;
			}
			.label{
				font-size: 12px;
				color: rgba(0,0,0,0.54);
			}
		}
	}
</style>
